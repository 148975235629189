import styled from "styled-components";

export const GamesActionButton = styled.button<{ height: number }>`
  cursor: pointer;
  background: #1d2234;
  border-radius: 8px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 32px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  color: var(--daylight-50-color);
  background: var(--ghosty);
  border: none;
  text-transform: capitalize;
  user-select: none;

  height: ${({ height }) => `${height}px`};

  &[data-active="true"] {
    background: #7e8eb880;
    border: 2px solid #1b2035;
    box-shadow: 0px 0px 0px 1px #464f6a;
  }

  &:not([data-active="true"]) {
    &:not(:disabled):hover {
      background: #7e8eb840;
    }
  }

  &:not(:disabled):active {
    transform: scale(0.98);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.9;
  }
`;

export const GamesActionButtons = styled.div<{ gap?: number }>`
  display: grid;
  flex-grow: 1;
  grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
  gap: ${({ gap }) => (gap ? `${gap}px` : "15px")};
  min-width: 90px;
`;

export const GamesFooterActionButton = styled.div`
  border: 1px solid var(--dark-rainy-morning);
  border-radius: 8px;
  cursor: pointer;
  background: var(--dark-foggy-night-100);

  min-width: 40px;
  min-height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  &:hover {
    background: var(--dark-rainy-morning);
  }

  &:active {
    transform: scale(0.95);
  }

  &[data-absolute="true"] {
    position: absolute;
    z-index: 50;
    left: 20px;
    top: 20px;
  }
`;

export const GamesActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-family: "Golos Text";
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
`;
