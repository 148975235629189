import { useAppContext } from "contexts";
import { IDtoLeaderboardUser, TheLeaderboardSourceKeys } from "interfaces";
import { useQuery } from "@tanstack/react-query";

interface useLeaderboardUsersParams {
  casinoProvider?: string;
  leaderboardId?: string;
  take?: number;
  skip?: number;
  isAdmin?: boolean;
}

export enum LeaderboardLeadersViewState {
  EXPANDED = "expanded",
  COLLAPSED = "collapsed",
}

export default function useLeaderboardUsers({
  casinoProvider,
  leaderboardId,
  take = 10,
  skip = 0,
  isAdmin = false,
}: useLeaderboardUsersParams) {
  const { accessFetch } = useAppContext();

  return useQuery<{ items: IDtoLeaderboardUser[]; total: number }>({
    queryKey: ["leaderboard-users", { leaderboardId, take, casinoProvider, skip }],
    queryFn: async () => {
      const sort =
        casinoProvider === TheLeaderboardSourceKeys.hypedrop
          ? "deposited:desc"
          : "wageredTotal:desc";

      const idsUrl = `/leaderboard-user/${leaderboardId}/${
        isAdmin ? "" : "public/"
      }?sort=${sort}&skip=${skip}&take=${take}`;

      const activeUrl = `/leaderboard-user/${
        isAdmin ? "private" : "public"
      }/active-leaders?casinoProvider=${casinoProvider}&viewState=${
        take === 10 ? LeaderboardLeadersViewState.COLLAPSED : LeaderboardLeadersViewState.EXPANDED
      }`;
      const usersResponse = await accessFetch(leaderboardId ? idsUrl : activeUrl);

      const usersData = await usersResponse?.json();
      const { data, items } = usersData;
      const users = data ? data : items;
      return users ? { items: users, total: 0 } : { items: [], total: 0 };
    },
    retry: false,
    enabled: !!leaderboardId || !!casinoProvider,
    placeholderData: (previousData) => previousData,
    initialData: { items: [], total: 0 },
  });
}
