// import React from "react";

import { IDtoLeaderboard } from "interfaces";
import { useAppContext } from "contexts";
import { useQuery } from "@tanstack/react-query";
import { ExceptionHandlingType } from "types/apiServiceTypes";

export default function useLeaderboard({
  casinoProvider,
  leaderboardId,
}: {
  casinoProvider?: string;
  leaderboardId?: string;
}) {
  const { accessFetch } = useAppContext();

  return useQuery<IDtoLeaderboard>({
    queryKey: ["leaderboard", { casinoProvider, leaderboardId }],
    queryFn: async () => {
      const idsUrl = `/leaderboard/${leaderboardId}/public`;
      const activeUrl = `/leaderboard/active-leaderboard-info?casinoProvider=${casinoProvider}`;

      const leaderboardResponse = await accessFetch(leaderboardId ? idsUrl : activeUrl);
      const leaderboardData = await leaderboardResponse?.json();
      if (!leaderboardResponse?.ok) {
        return Promise.reject(leaderboardData);
      }
      return leaderboardId ? leaderboardData?.item : leaderboardData.data;
    },

    retry: false,
    enabled: !!casinoProvider || !!leaderboardId,
  });
}
