import RaffleStartWrapper from "components/admin/notifications/RaffleStartWrapper";
import RaffleEndWrapper from "components/admin/notifications/RaffleEndWrapper";
import React from "react";
import { ToastContainer, ToastOptions, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMediaQuery } from "react-responsive";
import StreamerCardWrapper from "components/admin/notifications/StreamerCardWrapper";
import { LiveStreamData } from "helpers/types";
import { EndedRaffleData, LeaderboardRaffleStartData, RaffleWithIdData } from "types/raffles";
import { NotificationData } from "types/notification";
import GenericNotificationCard from "components/admin/notifications/NotificationCard";
import LeaderboardRaffleStartWrapper from "components/admin/notifications/LeaderboardRaffleStartWrapper";

function Notification() {
  const isMobile = useMediaQuery({
    query: "(max-width: 500px)",
  });

  return (
    <ToastContainer
      containerId="toast"
      toastClassName="toastClassName"
      style={isMobile ? { marginTop: 140 } : { marginTop: 80 }}
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark"
    />
  );
}

type ReactToastType =
  | "error"
  | "info"
  | "success"
  | "warning"
  | "raffle-start"
  | "raffle-end"
  | "stream-live"
  | "default"
  | "calendar-day"
  | "leaderboard-raffle-start";

const triggerNotification = ({
  text,
  type,
  toastId,
  raffleEndToastProps,
  raffleStartToastProps,
  leaderboardRaffleToastProps,
  calendarToastProps,
  streamToastProps,
}: {
  text?: string;
  type: ReactToastType;
  toastId?: string;
  raffleEndToastProps?: NotificationData<EndedRaffleData>;
  raffleStartToastProps?: NotificationData<RaffleWithIdData>;
  leaderboardRaffleToastProps?: NotificationData<LeaderboardRaffleStartData>;
  calendarToastProps?: NotificationData<null>;
  streamToastProps?: NotificationData<LiveStreamData>;
}) => {
  switch (type) {
    case "error":
      toast.error(`${text}`);
      break;
    case "info":
      toast.info(`${text}`);
      break;
    case "success":
      toast.success(`${text}`);
      break;
    case "warning":
      toast.warning(`${text}`);
      break;
    case "raffle-end": {
      toast(
        raffleEndToastProps ? (
          <RaffleEndWrapper
            props={raffleEndToastProps.data}
            notificationData={raffleEndToastProps.notificationData}
          />
        ) : null,
        {
          ...getRaffleToastOptions("#ee2a2d3f"),
          toastId: toastId ?? "raffle-end",
        }
      );
      break;
    }
    case "stream-live": {
      toast(
        streamToastProps ? (
          <StreamerCardWrapper
            props={streamToastProps.data}
            notificationData={streamToastProps.notificationData}
          />
        ) : null,
        {
          ...getRaffleToastOptions("#424991bf"),
        }
      );
      break;
    }
    case "raffle-start":
      toast(
        raffleStartToastProps ? (
          <RaffleStartWrapper
            props={raffleStartToastProps.data}
            notificationData={raffleStartToastProps.notificationData}
          />
        ) : null,
        {
          ...getRaffleToastOptions("#2aee934d"),
          toastId: toastId ?? "raffle-start",
        }
      );
      break;
    case "leaderboard-raffle-start":
      toast(
        leaderboardRaffleToastProps ? (
          <LeaderboardRaffleStartWrapper
            props={leaderboardRaffleToastProps.data}
            notificationData={leaderboardRaffleToastProps.notificationData}
          />
        ) : null,
        {
          ...getRaffleToastOptions("#2aee934d"),
          toastId: toastId ?? "leaderboard-raffle-start",
        }
      );
      break;
    case "calendar-day":
      toast(
        calendarToastProps ? (
          <GenericNotificationCard {...calendarToastProps.notificationData} />
        ) : null,
        {
          ...getRaffleToastOptions("rgba(255, 177, 68, 0.5)"),
          toastId: toastId ?? "calendar-day",
        }
      );
      break;
    case "default":
      toast(`${text}`);
      break;
    default:
      break;
  }
};
function getRaffleToastOptions(progressColor: string): ToastOptions {
  return {
    closeButton: false,
    style: {
      width: "fit-content",
      padding: 0,
      borderRadius: "8px",
    },
    progressStyle: {
      backgroundColor: progressColor,
      height: "6px",
      borderBottomLeftRadius: "8px",
    },
    bodyStyle: {
      borderRadius: "8px",
      padding: 0,
    },
  };
}

export { Notification, triggerNotification };
