import { Flex } from "components/common";
import CurrencyNumbers from "components/wrewards-ui-kit/CurrencyNumbers";
import { IDtoLeaderboard, IDtoLeaderboardUser } from "interfaces";

import React, { memo, useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { Avatar } from "@chakra-ui/react";
import { DEFAULT_AVATAR, SocketEvent } from "helpers/constants";
import { useMediaQuery } from "react-responsive";
import ChakraModal from "components/wrewards-ui-kit/Modal/WrewardsModal";
import WrewardsButton from "components/wrewards-ui-kit/Button";
import RaffleLeaderboardPrizeTable from "./RaffleLeaderboardPrizeTable";
import useLeaderboardRaffleWinner from "hooks/leaderboards/useLeaderboardRaffleWinners";
import LeaderboardRaffleRoulette from "./raffle-leaderboard/LeaderboardRaffleRoulette";
import { LeaderboardRaffleContendor } from "types/leaderboard/leaderboard-raffle.ts";
import Replay from "/public/images/icons/rtp.svg";
import { socket } from "socket";

type LeaderboardRafflePrizeGridProps = {
  leaderboard?: IDtoLeaderboard;
};
interface LeaderboardRaffleRouletteParticipant {
  refereeId: string;
  displayName: string;
  amount: number;
  position: number;
}
function LeaderboardRafflePrizeGridGeneral({ leaderboard }: LeaderboardRafflePrizeGridProps) {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useMediaQuery({
    query: "(max-width: 900px)",
  });

  const {
    refetch,
    data: { items },
  } = useLeaderboardRaffleWinner({
    leaderboardId: leaderboard?.id as string,
    take: (leaderboard?.additionalPrizes?.length || 0) + 3,
  });
  const numbersToTake = isMobile ? 7 : 8;

  const prizes = useMemo(
    () => [
      { prizeNumber: 1, amount: leaderboard?.prize1 },
      { prizeNumber: 2, amount: leaderboard?.prize2 },
      { prizeNumber: 3, amount: leaderboard?.prize3 },
      ...(leaderboard?.additionalPrizes ?? []),
    ],
    [leaderboard]
  );

  const prizeWithWinnersAll = useMemo(
    () =>
      prizes
        .map((prize) => ({
          ...prize,
          winner: items.find((v) => v.position === prize.prizeNumber),
        }))
        .sort((a, b) => a.prizeNumber - b.prizeNumber),
    [items, prizes]
  );

  const prizeWithWinners = useMemo(() => {
    return prizeWithWinnersAll.slice(0, numbersToTake);
  }, [numbersToTake, prizeWithWinnersAll]);

  const [winner, setWinner] = useState<LeaderboardRaffleContendor>();
  const [isRouletteFinished, setIsRouletteFinished] = useState<boolean>(false);

  useEffect(() => {
    const handler = (winner: LeaderboardRaffleRouletteParticipant) => {
      // const decodedString = Buffer.from(data, "base64").toString("ascii");

      setWinner({
        id: Math.floor(Math.random() * 20) + 20,
        image: DEFAULT_AVATAR,
        prize: winner.amount,
        text: winner.displayName,
        refereeId: winner.refereeId,
        position: winner.position,
      });
    };
    socket.on(SocketEvent.LeaderboardRaffleWinnerPing, handler);
    return () => {
      socket.off(SocketEvent.LeaderboardRaffleWinnerPing, handler);
    };
  }, []);

  return (
    <>
      {!isRouletteFinished && winner && (
        <LeaderboardRaffleRoulette
          finishRoulette={() => {
            refetch();
            setIsRouletteFinished(true);
          }}
          newWinners={[winner]}
        />
      )}

      {isRouletteFinished && winner && (
        <RouletteContendorContainer>
          <HighlightedText>Join next leaderboard to win more prizes</HighlightedText>
        </RouletteContendorContainer>
      )}

      <Flex column align="center" justify="center" gap={32} maxWidth={900} margin="0 auto">
        <WinnersGrid>
          {prizeWithWinners.map((prize) => {
            return (
              <LeaderRafflePrizeCard key={prize.prizeNumber}>
                <div className="place">{prize.prizeNumber}</div>
                <div className="info">
                  <div className="prize">${prize.amount}</div>

                  <div className={`winner ${prize.winner ? "column" : "row"}`}>
                    <div>
                      <Avatar size="sm" src={prize.winner?.avatar ?? DEFAULT_AVATAR} />
                    </div>
                    <div className="displayName">{prize.winner?.displayName || "TBD"}</div>
                    <CurrencyNumbers icon="Raffle">
                      #{prize.winner?.proof.winnersEntryNumbers?.[0] || "-"}
                    </CurrencyNumbers>
                  </div>
                </div>
              </LeaderRafflePrizeCard>
            );
          })}
        </WinnersGrid>
        <WrewardsButton
          variant="game-action-gray"
          style={{ width: "80%" }}
          onClick={() => setIsOpen(true)}
        >
          More Prizes
        </WrewardsButton>
      </Flex>

      <ChakraModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        content={
          <RaffleLeaderboardPrizeTable
            data={prizeWithWinnersAll}
            ticketRate={leaderboard?.ticketRate}
          />
        }
        size="2xl"
        bodyStyle={css`
          padding: 20px 6px !important;
          overflow: auto;
        `}
      />
    </>
  );
}
const HighlightedText = styled.b`
  left: 15px;
  top: 10px;

  text-transform: uppercase;
  font-family: var(--font-family-exo);
  font-size: 26px;
  font-weight: 800;
  line-height: normal;

  & {
    background: linear-gradient(
      130deg,
      #ffce64 27.05%,
      #ffd48e 43.67%,
      #fff 57.81%,
      #ffd48e 73.37%,
      #ffce64 97.77%
    );

    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &::before {
    padding: 0;
    content: attr(data-content);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background: linear-gradient(280deg, #2b271e 0%, #6d5f40 100%);
    background-size: 200% 200%;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-stroke: 3px transparent;
  }
`;

const RouletteContendorContainer = styled(Flex)`
  justify-content: center;
  align-items: center;

  height: 150px;
  width: 100%;
  opacity: 0;
  animation: reveal 0.7s;
  animation-fill-mode: forwards;

  @keyframes reveal {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const LeaderRafflePrizeCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  min-width: 140px;
  flex: 1;

  .displayName {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }

  .info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  .winner {
    color: rgba(200, 215, 245, 0.5);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    &.column {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .place {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 48px;
    height: 48px;
    font-family: var(--font-family-exo);
    font-weight: 900;
    font-size: 20px;
    font-style: italic;
    color: var(--daylight-75, rgba(200, 215, 245, 0.75));
    &::before {
      content: "";
      display: block;
      position: absolute;
      inset: 0px;
      border-radius: 8px;
      transform: rotate(45deg);
      aspect-ratio: 1/1;
      background-color: #2a3046;
      z-index: -1;
    }
  }

  .prize {
    font-family: var(--font-family-exo);
    padding: 0 6px;
    font-style: italic;
    font-weight: 900;
    font-size: 24px;
    line-height: 24px;
    color: var(--daylight-color, #d5e0f5);
  }

  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3) {
    .prize {
      font-family: var(--font-family-exo);
      padding: 0 6px;
      font-style: italic;
      font-weight: 800;
      font-size: 32px;
      line-height: 32px;
      background: linear-gradient(
        130deg,
        #ffce64 27.05%,
        #ffd48e 43.67%,
        #fff 57.81%,
        #ffd48e 73.37%,
        #ffce64 97.77%
      );
      font-weight: 900;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .place {
      color: #141722;
      text-shadow: 0px 0px 3px #fff;
    }
  }

  /* Apply the grid area for each specific item */
  &:nth-child(1) {
    grid-area: item1;
    position: relative;
    top: -40px;
    .place {
      width: 90px;
      height: 90px;
      font-size: 54px;
      &::after {
        content: "";
        display: block;
        position: absolute;
        inset: 0;
        border-radius: 8px;
        transform: rotate(45deg) translate(-8px, -8px);
        aspect-ratio: 1/1;

        background: linear-gradient(135deg, rgba(51, 49, 41, 0) 0%, #3331298c 20%, #f6ce5e 100%);

        z-index: -2;
      }

      &::before {
        border: 2px solid #927f4e;
        filter: drop-shadow(0px 6px 32px rgba(247, 212, 116, 0.2));

        background: linear-gradient(
          135deg,
          #ffefd3 0%,
          #ffefd3 20.5%,
          #fcf2de 24.5%,
          #ffd16c 74.5%,
          #ffcf65 100%
        );
      }
    }
  }
  &:nth-child(2) {
    grid-area: item2;
    .place {
      width: 72px;
      height: 72px;
      font-size: 40px;
      &::after {
        content: "";
        display: block;
        position: absolute;
        inset: 0;
        border-radius: 8px;
        transform: rotate(45deg) translate(-8px, 9px);
        aspect-ratio: 1/1;
        background: linear-gradient(45deg, rgba(43, 49, 56, 0) 0%, #2b3138 30%, #a4b6be 100%);
        z-index: -2;
      }

      &::before {
        border: 1px solid #e3f9ff;
        filter: drop-shadow(0px 6px 32px rgba(181, 200, 209, 0.2));

        background: linear-gradient(
          65deg,
          #d1e6f0 0%,
          #e8f7ff 20.5%,
          #d1e6f0 34.5%,
          #c3d9e2 74.5%,
          #9aacb3 100%
        );
        z-index: -1;
      }
    }
  }
  &:nth-child(3) {
    grid-area: item3;
    .place {
      width: 72px;
      height: 72px;
      font-size: 40px;
      &::after {
        content: "";
        display: block;
        position: absolute;
        inset: 0;
        border-radius: 8px;
        transform: rotate(-45deg) translate(8px, 8px);
        aspect-ratio: 1/1;

        background: linear-gradient(-45deg, rgba(82, 60, 54, 0) 0%, #3331298c 20%, #ab6655 100%);
        z-index: -2;
      }

      &::before {
        border: 1px solid #daa497;
        filter: drop-shadow(0px 6px 32px rgba(179, 105, 86, 0.2));
        background: linear-gradient(
          -155deg,
          #a76a4a 0%,
          #ae6e53 0.5%,
          #f9a68a 34.5%,
          #ba7666 74.5%,
          #ba7766 100%
        );
      }
    }
  }
  &:nth-child(4) {
    grid-area: item4;
  }
  &:nth-child(5) {
    grid-area: item5;
  }
  &:nth-child(6) {
    grid-area: item6;
  }
  &:nth-child(7) {
    grid-area: item7;
  }
  &:nth-child(8) {
    grid-area: item8;
  }
`;

const WinnersGrid = styled.div`
  padding-top: 80px;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: auto auto;
  gap: 48px 32px;
  justify-items: center;
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  grid-template-areas:
    ". item2 item2 . item1 item1 . item3 item3 ."
    "item4 item4 item5 item5 item6 item6 item7 item7 item8 item8";

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: initial;

    ${LeaderRafflePrizeCard} {
      grid-area: initial;

      min-width: 100px;

      &:nth-child(1) {
        grid-column: 1 / span 2;
      }
    }
  }
`;

const ReplayButton = styled.button`
  display: flex;
  justify-content: center;
  gap: 8px;
  transition: all ease-in-out 0.2s;
  padding: 16px 24px;

  border-radius: 74px;
  border: 2px solid #b48b23;
  background: linear-gradient(
      103deg,
      rgba(208, 219, 255, 0) -21.86%,
      rgba(209, 219, 255, 0.08) 32.26%,
      rgba(209, 219, 255, 0.19) 45.78%,
      rgba(215, 224, 255, 0.08) 59.31%,
      rgba(222, 231, 255, 0) 113.42%
    ),
    rgba(49, 56, 81, 0.15);
  box-shadow: 0px 0px 0px 5px #171a28 inset;
  backdrop-filter: blur(44px);

  color: var(--daylight-color, #d5e0f5);
  font-family: var(--font-family-exo);
  font-size: 24px;
  font-style: italic;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;

  .icon-wrapper {
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:active {
    transform: scale(0.95);
    box-shadow: 0px 1px 0px 0px #055ba0;
  }
`;
export default memo(LeaderboardRafflePrizeGridGeneral);
