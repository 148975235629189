import { UserConnectionType } from "types/black-list";

export interface Role {
  name: string;
  id: number;
}
export interface IUserDto {
  id: string;
  isAdmin: boolean;
  displayName: string;
  login: string;
  gamdomId?: number;
  discordId: string | null;
  discordUsername: string | null;
  profileImageUrl?: string;
  mfaSecret?: string;
  isBanned: boolean;
  hasVerifiedRoleOnDiscordGuild: boolean;
  banReason?: string;
  rafflesWon?: number;
  offlineImageUrl?: string;
  createdAt: string;
  pointsBalance?: number;
  pointsSpent?: number;
  viewCount?: number;
  roles: Role[];
  steamTradeLink?: string;
  usdtTrc20Address?: string | null;
  usdtErc20Address?: string | null;
  btcAddress?: string | null;
  ethAddress?: string | null;
  ltcAddress?: string | null;
  email?: string;
  createdTwitchAt?: string;
  gPointsBalance?: number;
  isGPointsClaimed: boolean;
  userMultiConnections: MultiConnection[];
}

export type MultiConnection = {
  ignoreReason?: string;
  isIgnored: boolean;
  type: UserConnectionType;
  updatedAt?: Date;
  userId: string;
  value: string;
};

export enum BalanceType {
  G_POINT = "G_POINT",
  POINTS = "POINTS",
}

export interface IUserBalanceUpdate {
  userId: string;
  balanceType: BalanceType;
  balance: number;
}

export interface ExchangeRateDto {
  point_gpoint_rate: number;
  daily_free_g_points: number;
}

export interface Guest {
  id: string;
  createdAt: string;
  pointsSpent: number;
  userId: string;
  discordId: string;
  discordUsername: string;
  isWinner: boolean;
  winDate: string | null;
  message: string;
  microphone: boolean;
  camera: boolean;
  login: string;
  displayName: string;
  userCreatedAt: string;
  offlineImageUrl: string;
  profileImageUrl: string;
  pointsBalance: number;
}
