import { useLocalStorage } from "usehooks-ts";
import { memo } from "react";
import UnMute from "/public/images/icons/volume.svg";
import Mute from "/public/images/icons/volume-none.svg";
import styled from "styled-components";
import { GamesFooterActionButton } from "../GamesActions";
import { LS_SOUND_KEY } from "types/local-storage";

const MuteButtonController: React.FC<{ absolute?: boolean }> = ({ absolute }) => {
  const [isSoundEnabled, setSoundEnabled] = useLocalStorage(LS_SOUND_KEY, true, {
    initializeWithValue: true,
  });

  return (
    <GamesFooterActionButton
      data-absolute={absolute}
      onClick={() => {
        setSoundEnabled((prev) => !prev);
      }}
    >
      <MuteIcons>{isSoundEnabled ? <UnMute /> : <Mute />}</MuteIcons>
    </GamesFooterActionButton>
  );
};

const MuteIcons = styled.div`
  height: 16px;
  width: 16px;
  & > svg {
    width: 16px;
    height: 16px;
    filter: invert(38%) sepia(3%) saturate(1544%) hue-rotate(188deg) brightness(93%) contrast(86%);
  }
`;

export default memo(MuteButtonController);
