import { Flex } from "components/common";
import Image from "next/image";
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import RoulettePro from "react-roulette-pro";
import "react-roulette-pro/dist/index.css";
import styled, { css } from "styled-components";
import { nanoid } from "nanoid";
import { ContenderInfo } from "types/raffles";
import { DEFAULT_AVATAR } from "helpers/constants";
import { hiddenText } from "helpers/hiddenText";
import Angle from "/public/images/icons/angle.svg";
import { PrizeType } from "react-roulette-pro/dist/types";
import { LeaderboardRaffleContendor } from "types/leaderboard/leaderboard-raffle.ts";
import { Avatar, position } from "@chakra-ui/react";
import { formatNumber } from "../utils/formatNumbers";
import { truncateText } from "helpers/truncateText";
import { Howl } from "howler";
import GamesMuteButton from "components/games/shared/GamesMuteButton";
import { useLocalStorage } from "usehooks-ts";
import { LS_SOUND_KEY } from "types/local-storage";

interface RouletteProps {
  newWinners: LeaderboardRaffleContendor[];
  finishRoulette: () => void;
}

const generateRandomNickname = (): string => {
  const adjectives = [
    "Swift",
    "Mighty",
    "Brave",
    "Fierce",
    "Clever",
    "Sneaky",
    "Lucky",
    "Bold",
    "Silent",
    "Quick",
    "Loyal",
    "Witty",
    "Noble",
    "Shadowy",
    "Blazing",
  ];

  const animals = [
    "Tiger",
    "Wolf",
    "Hawk",
    "Eagle",
    "Fox",
    "Bear",
    "Dragon",
    "Shark",
    "Lion",
    "Panther",
    "Raven",
    "Serpent",
    "Phoenix",
    "Falcon",
    "Cobra",
  ];

  const numbers = Math.floor(Math.random() * 10000); // 0-9999 for uniqueness
  const adjective = adjectives[Math.floor(Math.random() * adjectives.length)];
  const animal = animals[Math.floor(Math.random() * animals.length)];

  return `${adjective}${animal}${numbers}`;
};

const LeaderboardRaffleRoulette: React.FC<RouletteProps> = ({ finishRoulette, newWinners }) => {
  const alreadyShownPositions = useRef<Set<number>>(new Set());
  const isInitialized = useRef<boolean>(false);
  const initializationInterval = useRef<any>();
  const [secTillStart, setSecTillStart] = useState(4);
  const [winnerQueue, setWinnersQueue] = useState<LeaderboardRaffleContendor[]>([]);
  const [currentRollingWinner, setCurrentRollingWinner] =
    useState<null | LeaderboardRaffleContendor>(null);
  const [start, setStart] = useState(false);
  const [isRolling, setIsRolling] = useState(false);
  const [showPrize, setShowPrize] = useState(false);
  const [participants, setParticipants] = useState<LeaderboardRaffleContendor[]>([]);
  const roll = useRef<Howl | null>(null);
  const victory = useRef<Howl | null>(null);
  const [isSoundEnabled] = useLocalStorage(LS_SOUND_KEY, true, {
    initializeWithValue: true,
  });

  useEffect(() => {
    roll.current = new Howl({
      src: ["/audio/leaderboard/raffle/roulette-spin.mp3"],
    });

    victory.current = new Howl({
      src: ["/audio/leaderboard/raffle/victory.mp3"],
    });
    return () => {
      roll.current?.stop();
      victory.current?.stop();
    };
  }, []);

  useEffect(() => {
    roll.current && roll.current.mute(!isSoundEnabled);
    victory.current && victory.current.mute(!isSoundEnabled);
  }, [isSoundEnabled]);

  const getRandomContenders = useCallback(
    (count: number, startIndex: number = 0) =>
      Array.from({ length: count }, (_, i) => ({
        id: startIndex + i,
        text: hiddenText(generateRandomNickname()),
        image: DEFAULT_AVATAR,
      })),
    []
  );

  const winnerIndex = (currentRollingWinner?.id as number) ?? 22;

  useEffect(() => {
    const newParticipants = [
      ...getRandomContenders(winnerIndex),
      ...(currentRollingWinner ? [currentRollingWinner] : []),
      ...getRandomContenders(45 - 31, winnerIndex + 1),
    ];

    setParticipants(newParticipants);
  }, [getRandomContenders, currentRollingWinner]);

  useEffect(() => {
    if (newWinners.length > 0) {
      const winnersToDisplay = newWinners.filter(
        (newWinner) => !alreadyShownPositions.current.has(newWinner.position!)
      );
      winnersToDisplay.map((newWinner) => alreadyShownPositions.current.add(newWinner.position!));
      setWinnersQueue((prev) => [...prev, ...winnersToDisplay]);
    }
  }, [newWinners]);

  useEffect(() => {
    return () => {
      if (initializationInterval.current) clearInterval(initializationInterval.current);
    };
  }, []);

  useEffect(() => {
    if (isRolling === true && start === false) {
      setTimeout(
        () => {
          setStart(true);
          roll.current?.play();
        },
        !isInitialized.current ? 4000 : 0
      );

      if (!isInitialized.current) {
        isInitialized.current = true;
        initializationInterval.current = setInterval(() => {
          setSecTillStart((prev) => prev - 1);
        }, 1000);
      }
    }
  }, [isRolling, start]);

  useEffect(() => {
    if (isRolling === true || winnerQueue.length === 0) {
      return;
    }
    setIsRolling(true);

    const popWinnerFromQueueAndSetWinnerToRoll = () => {
      setWinnersQueue((prevQueue) => {
        if (prevQueue.length === 0) {
          return prevQueue;
        }
        const [firstWinner, ...rest] = prevQueue;
        setCurrentRollingWinner(firstWinner);
        return rest;
      });
    };

    popWinnerFromQueueAndSetWinnerToRoll();
    setStart(false);
    setShowPrize(false);
  }, [isRolling, winnerQueue.length, currentRollingWinner]);

  return (
    <RouletteContainer>
      <RouletteInnerContainer>
        {secTillStart > 0 && <InitializedText>Roulette starts in {secTillStart}</InitializedText>}
        <GamesMuteButton absolute={true} />

        <TopCenter>
          <Angle />
        </TopCenter>
        <RoulettePro
          prizes={participants}
          onPrizeDefined={() => {
            setShowPrize(true);

            victory.current?.play();
            setTimeout(() => {
              if (currentRollingWinner?.position === 15) {
                finishRoulette();
              }
              setIsRolling(false);
            }, 3000);
          }}
          prizeIndex={winnerIndex}
          start={start}
          options={{
            withoutAnimation: false,
            stopInCenter: false,
          }}
          spinningTime={4.5}
          defaultDesignOptions={{ prizesWithText: true, hideCenterDelimiter: false }}
          designPlugin={() => {
            const prizeItemWidth: number = 212;
            const prizeItemHeight: number = 225;
            return {
              prizeItemWidth,
              prizeItemHeight,
            };
          }}
          prizeItemRenderFunction={(el: LeaderboardRaffleContendor) => (
            <RouletteContendorContainer>
              <RaffleLeaderboardRandomWinnerCard
                prize={showPrize ? el.prize : undefined}
                displayName={el.text}
                avatar={DEFAULT_AVATAR}
                position={showPrize ? el.position : undefined}
              />
            </RouletteContendorContainer>
          )}
        />
      </RouletteInnerContainer>
    </RouletteContainer>
  );
};

export default LeaderboardRaffleRoulette;

const RaffleLeaderboardRandomWinnerCard: React.FC<{
  displayName: undefined | string;
  prize?: number;
  avatar: string;
  position?: number;
}> = ({ displayName, prize, avatar, position }) => {
  const show = !!prize;
  return (
    <Card data-scale={show}>
      {position && <Position>{position}</Position>}

      <CardContent>
        <UserAvatarWrapper>
          <Avatar size="xl" src={avatar ?? DEFAULT_AVATAR} />
        </UserAvatarWrapper>
        <DisplayName>{truncateText(displayName || "UnknownPlayer", 10)}</DisplayName>
        <Prize>
          {prize && (
            <PrizeValue data-content={`$${formatNumber(prize)}`}>${formatNumber(prize)}</PrizeValue>
          )}
        </Prize>
      </CardContent>
      <GradientBorder />
    </Card>
  );
};

const DisplayName = styled.span`
  color: var(--daylight-daylight-main, #d5e0f5);
  font-family: var(--font-family-exo);
  font-size: 20px;
  font-style: italic;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  text-align: left;
  position: relative;
  filter: drop-shadow(0px 0px 6px #000);
`;

const UserAvatarWrapper = styled.div`
  border: 2px solid #10131a;
  border-radius: 50%;
`;
const Card = styled.div`
  position: relative;
  height: 225px;
  transition: transform 0.3s;
  &[data-scale="true"] {
    transform: scale(1.3);
    z-index: 11;
  }

  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  min-width: 200px;
`;

const GradientBorder = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  z-index: 0;
  border-radius: inherit;

  &::before {
    content: "";
    display: block;
    position: absolute;
    border-radius: 8px;
    z-index: 0;
    inset: -1px;

    background: linear-gradient(180deg, #b48b23 0%, #ffd97a 24%, rgba(0, 0, 0, 0) 100%),
      linear-gradient(180deg, #181e30 0%, rgba(0, 0, 0, 0) 100%);
  }
`;

const Position = styled.b`
  position: absolute;
  left: 15px;
  top: 10px;
  z-index: 11;
  opacity: 0;
  animation: reveal 0.7s;
  animation-fill-mode: forwards;

  font-family: var(--font-family-exo);
  font-size: 26px;
  font-weight: 800;
  line-height: normal;

  & {
    background: linear-gradient(
      130deg,
      #ffce64 27.05%,
      #ffd48e 43.67%,
      #fff 57.81%,
      #ffd48e 73.37%,
      #ffce64 97.77%
    );

    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &::before {
    padding: 0;
    content: attr(data-content);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background: linear-gradient(280deg, #2b271e 0%, #6d5f40 100%);
    background-size: 200% 200%;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-stroke: 3px transparent;
  }

  @keyframes reveal {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const CardContent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 6px;
  z-index: 10;
  border-radius: inherit;
  box-shadow: 0px 0px 0px 3px #11161e inset;
  background-color: #181e30;
  overflow: hidden;
  &::before {
    border-radius: inherit;
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(
      105deg,
      rgba(208, 219, 255, 0) -24.09%,
      rgba(209, 219, 255, 0.02) 47.65%,
      rgba(209, 219, 255, 0.09) 65.58%,
      rgba(215, 224, 255, 0.02) 83.52%,
      rgba(222, 231, 255, 0) 155.26%
    );
    transform: rotate(45deg) scale(1.5);
  }

  @keyframes glassSpark {
    0% {
      transform: rotate(0deg) scale(1);
    }
    50% {
      transform: rotate(45deg) scale(1.5);
      opacity: 0;
    }
    100% {
      transform: rotate(0deg) scale(1);
    }
  }
`;

const PrizeValue = styled.b`
  opacity: 0;
  animation: reveal 0.7s;
  animation-fill-mode: forwards;

  @keyframes reveal {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const Prize = styled.div`
  color: var(--daylight-color, #d5e0f5);
  font-family: var(--font-family-exo);
  font-size: 24px;
  font-style: italic;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 0;
  height: 100px;

  & b {
    position: relative;
    background: linear-gradient(
      130deg,
      #ffce64 27.05%,
      #ffd48e 43.67%,
      #fff 57.81%,
      #ffd48e 73.37%,
      #ffce64 97.77%
    );

    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  & b::before {
    padding: 0;
    content: attr(data-content);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background: linear-gradient(280deg, #2b271e 0%, #6d5f40 100%);
    background-size: 200% 200%;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-stroke: 3px transparent;
  }
`;

const TopCenter = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
`;

const InitializedText = styled.div`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  position: absolute;
  font-family: var(--font-family-exo);
  font-size: 40px;
  font-style: italic;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 2px;
  text-transform: uppercase;

  & b {
    position: relative;
    background: linear-gradient(
      130deg,
      #ffce64 27.05%,
      #ffd48e 43.67%,
      #fff 57.81%,
      #ffd48e 73.37%,
      #ffce64 97.77%
    );

    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;
const RouletteInnerContainer = styled(Flex)`
  width: 100%;
  justify-content: center;
  position: relative;
  padding: 2px 1px;
  border-radius: 8px;
  box-shadow: rgb(17, 22, 30) 0px 0px 0px 3px inset;
  background-color: rgb(24, 30, 48);
  position: relative;
  max-width: 1280px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    border-radius: 12px;
    z-index: 0;
    inset: -1px;
    background: linear-gradient(rgb(180, 139, 35) 0%, rgb(255, 217, 122) 24%, rgba(0, 0, 0, 0) 100%),
      linear-gradient(rgb(24, 30, 48) 0%, rgba(0, 0, 0, 0) 100%);
  }

  & .roulette-pro-wrapper {
    border-radius: 8px;
    width: 100%;
    max-width: 1280px;
    margin: 0px auto;
    position: relative;
    background-color: rgb(24, 30, 48);
  }
`;

const RouletteContainer = styled(Flex)`
  justify-content: center;
  position: relative;
  opacity: 0;
  animation: reveal 1.3s;
  animation-fill-mode: forwards;

  @keyframes reveal {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const RouletteContendorContainer = styled(Flex)`
  justify-content: center;
  flex-direction: column;
  height: 300px;
  width: 200px;
  align-items: center;
  box-sizing: border-box;
  margin: 0 6px;
`;
