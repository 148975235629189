export type TTheLeaderboardSourceKeys =
  | "gamdom"
  | "hypedrop"
  | "packdraw"
  | "wrewards"
  | "clash"
  | "cases"
  | "rain_gg"
  | "luxdrop";

export const TheLeaderboardSourceKeys = {
  gamdom: "GAMDOM",
  hypedrop: "HYPEDROP",
  packdraw: "PACKDRAW",
  wrewards: "WREWARDS",
  clash: "CLASH",
  cases: "CASES",
  rain_gg: "RAIN",
  luxdrop: "LUXDROP",
};
