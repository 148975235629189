import React from "react";
import GenericNotificationCard from "components/admin/notifications/NotificationCard";
import { NotificationToastProps } from "types/notification";
import { LeaderboardRaffleStartData, RaffleWithIdData } from "types/raffles";
import { css } from "styled-components";

const LeaderboardRaffleStartWrapper = ({
  props,
  notificationData,
}: NotificationToastProps<LeaderboardRaffleStartData>) => {
  const { leaderboardId, provider } = props;
  const { description, title, info, type } = notificationData;

  return (
    <GenericNotificationCard
      containerStyles={css`
        max-width: 240px;
      `}
      title={title}
      description={description}
      type={type}
      info={{
        actionButtonText: info.actionButtonText,
        onActionClickLink: `/leaderboards?provider=${provider}`,
      }}
    />
  );
};

export default LeaderboardRaffleStartWrapper;
