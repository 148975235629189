import { IUserDto, Role } from "interfaces";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useAppContext } from "contexts";
import router from "next/router";
import { useMemo } from "react";
import { ExceptionHandlingType } from "types/apiServiceTypes";

export default function useCurrentUser() {
  const { apiService } = useAppContext();
  return useQuery<IUserDto | null>({
    queryKey: ["user/me"],
    queryFn: async () => {
      const roles = await apiService.get<Role[]>("/user/roles", {
        exceptionHandlingType: ExceptionHandlingType.SILENT,
      });

      const data = await apiService.get<{ user: IUserDto | null }>("/user/me");
      if (data.user) {
        const finalRoles = Array.isArray(roles) ? roles : [];
        data.user.isAdmin = finalRoles.length > 0;
        data.user.roles = finalRoles;
      }
      return data.user;
    },
    retry: false,
    initialData: null,
    structuralSharing: (oldData, newData) => newData,
    notifyOnChangeProps: ["data", "isFetching", "refetch"],
  });
}

export function useUserClientActions() {
  const queryClient = useQueryClient();
  const { apiService, profile, accessURL, isProfileLoading } = useAppContext();

  const login = async () => {
    try {
      const url = accessURL(`/auth/twitch`) as URL;
      router.push(url.href);
    } catch (error) {
      console.log({ error });
    }
  };
  const isLoadingUserInfo = useMemo(
    () => apiService.getCanRefresh() && !profile && isProfileLoading,
    [apiService, isProfileLoading, profile]
  );
  const isNotLoggedIn = useMemo(() => !isLoadingUserInfo && !profile, [isLoadingUserInfo, profile]);

  const logout = () => {
    queryClient.setQueryData<IUserDto | null>(["user/me"], null);
    apiService.setCanRefresh(false);
  };

  return { login, logout, isLoadingUserInfo, isNotLoggedIn };
}
