export enum CasinoProvider {
  GAMDOM = "GAMDOM",
  HYPEDROP = "HYPEDROP",
  PACKDRAW = "PACKDRAW",
  WREWARDS = "WREWARDS",
  CLASH = "CLASH",
  CASES = "CASES",
  RAIN = "RAIN",
  LUXDROP = "LUXDROP",
}

export const LeaderboardCasinoProviders = {
  //   [CasinoProvider.HYPEDROP]: {
  //     wgtvHref: "https://hypedrop.com/r/WGTV",
  //     watchGamesTVHref: "https://hypedrop.com/r/watchgamestv",
  //     providerHref: "https://hypedrop.com/",
  //     providerName: "Hypedrop",
  //   },
  [CasinoProvider.GAMDOM]: {
    wgtvHref: "https://gamdom.com/r/wgtv",
    watchGamesTVHref: "https://gamdom.com/r/watchgamestv",
    providerHref: "https://gamdom.com/",
    providerName: "Gamdom",
  },
  [CasinoProvider.PACKDRAW]: {
    wgtvHref: "https://packdraw.com/?ref=wgtv",
    watchGamesTVHref: "https://packdraw.com/?ref=watchgamestv",
    providerHref: "https://packdraw.com/",
    providerName: "PackDraw",
  },
  [CasinoProvider.CLASH]: {
    wgtvHref: "https://clash.gg/r/WGTV",
    watchGamesTVHref: "https://clash.gg/r/watchgamestv",
    providerHref: "https://clash.gg",
    providerName: "Clash",
  },

  [CasinoProvider.CASES]: {
    wgtvHref: "https://cases.gg/r/WGTV",
    watchGamesTVHref: "https://cases.gg/r/watchgamestv",
    providerHref: "https://cases.gg",
    providerName: "Cases",
  },
  // [CasinoProvider.RAIN]: {
  //   wgtvHref: "https://rain.gg/r/WGTV",
  //   watchGamesTVHref: null,
  //   providerHref: "https://rain.gg",
  //   providerName: "Rain",
  // },
  [CasinoProvider.LUXDROP]: {
    wgtvHref: "https://luxdrop.com/r/WGTV",
    watchGamesTVHref: "https://luxdrop.com/r/WatchGamesTV",
    watchHref: "https://luxdrop.com/r/Watch",
    providerHref: "https://luxdrop.com/",
    providerName: "Luxdrop",
  },
};

export const LeaderboardCasinoProvidersArray = Object.values(LeaderboardCasinoProviders);
