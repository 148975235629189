import Image from "next/image";
import React from "react";
import styled from "styled-components";
import Link from "next/link";
import { useRouter } from "next/router";
import Pachiko from "assets/pachiko/images/pachiko-boy-desktop-2.png";

// import Stones from "assets/pachiko/images/stones-desktop.png";
import StonesDeskLeft from "assets/pachiko/images/stones-desk-1.svg";
import StonesDeskRight from "assets/pachiko/images/stones-desk-2.svg";
import PachikoTextImg from "assets/pachiko/images/pachiko-text.png";

import WrewardsButton from "components/wrewards-ui-kit/Button";

import KickIcon from "/public/images/icons/kick.svg";
import StarIcon from "/public/images/icons/star-2.svg";
import Coin from "/public/images/coins/w-coin.svg";
import Arrow from "/public/images/icons/arrow-down-filled.svg";
import { WatchGamesTVLinks } from "helpers/constants";

export const MainSection = () => {
  const router = useRouter();

  return (
    <MainSectionWrapper>
      <PachikoBoyWrapper>
        <PachikoBoyContainer>
          <StonesLeftContainer />
          <PachikoBoy src={Pachiko.src} width={565} height={465} alt="pachiko-boy" />
          <StonesRightContainer />
        </PachikoBoyContainer>
        <PachikoTextContainer>
          <PachikoTitle>Welcome to</PachikoTitle>

          <PachikoText>
            <Image src={PachikoTextImg.src} fill alt="pachiko" style={{ objectFit: "contain" }} />
          </PachikoText>
        </PachikoTextContainer>
      </PachikoBoyWrapper>

      <InfoWrapper>
        <sub className="subtitle">
          <StarIcon className="highlight" />
          <span className="highlight">I</span> WGTV KICK{" "}
          <span className="highlight">EXCLUSIVE</span>
        </sub>

        <div className="buttons">
          <Link target="_blank" href={"/points-shop"}>
            <WrewardsButton variant="pachiko-action-purple" style={{ width: "100%" }}>
              <Coin width={16} height={16} />
              Buy Pachinko Rounds
            </WrewardsButton>
          </Link>
          <Link target="_blank" href={WatchGamesTVLinks.kick}>
            <WrewardsButton variant="kick-action-green" style={{ width: "100%" }}>
              <KickIcon width={16} height={16} /> Follow WGTV Kick
            </WrewardsButton>
          </Link>
        </div>

        <div className="footer">
          <p className="info">Explore Pachinko</p>
          <a href={"#how-to-pachinko"}>
            <WrewardsButton variant="gray-icon-bordered">
              <Arrow />
            </WrewardsButton>
          </a>
        </div>
      </InfoWrapper>
    </MainSectionWrapper>
  );
};

const InfoWrapper = styled.div`
  padding: 16px 0;
  margin-top: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 32px;

  .subtitle {
    position: relative;
    font-family: var(--font-family-exo);
    font-style: italic;
    font-size: 24px;
    font-weight: 900;
    text-shadow: 0px 0px 10px #000;
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--daylight-main, #d5e0f5);
  }

  .highlight {
    color: #35f224;
  }

  .footer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin-bottom: 2rem;
  }

  .info {
    color: var(--daylight-daylight-75, rgba(200, 215, 245, 0.75));
  }

  .buttons {
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
    max-width: 500px;
    width: 100%;
    gap: 32px;

    button {
      flex: 1;
    }
  }

  @media (max-width: 800px) {
    margin-top: 0px;

    .buttons {
      padding: 16px;
      gap: 16px;
    }
  }
`;
const MainSectionWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: var(--ultra-wide);
  margin: 0 auto;
`;

const PachikoTextContainer = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(0, 40%);
  bottom: 0;
  z-index: 3;

  @media (max-width: 800px) {
    min-width: 314px;
    margin: 0;
    transform: translate(0, 0);
  }
`;

const PachikoText = styled.div`
  min-width: 796px;
  min-height: 150px;
  margin: 1rem 0;
  animation: pulse 5s infinite, shadow 5s infinite;

  @media (max-width: 800px) {
    min-width: 314px;
    min-height: 100px;
    margin: 0;
  }

  @keyframes shadow {
    0% {
      filter: drop-shadow(0px 10px 40px rgba(101, 27, 205, 0.4));
    }
    50% {
      filter: drop-shadow(0px 5px 100px rgba(101, 27, 205, 0.4));
    }
    100% {
      filter: drop-shadow(0px 10px 40px rgba(101, 27, 205, 0.4));
    }
  }
`;

const PachikoTitle = styled.div`
  font-family: var(--font-family-golos);
  font-size: 40px;
  font-weight: 700;
  z-index: 3;
  text-transform: uppercase;
  color: var(--daylight-color);
  margin-top: 20px;
  text-shadow: 0px 0px 10px #000;
  @media (max-width: 760px) {
    font-size: 25px;
    margin-top: 0;
  }
  @media (max-width: 560px) {
    font-size: 20px;
  }
`;

const PachikoBoyWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1080px) {
    width: 1080px;
  }
`;

const PachikoBoyContainer = styled.div`
  position: relative;

  @media (max-width: 760px) {
    padding: 0 10%;
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 110%;
    filter: blur(150px);
    background: radial-gradient(rgba(0, 178, 255, 0.2) 20%, rgba(105, 0, 255, 1) 100%) center top;
    @media (max-width: 760px) {
      width: calc(100% - 20%);
      left: 10%;
    }
  }
`;

const PachikoBoy = styled(Image)`
  z-index: 2;
  border-bottom-left-radius: 75px;
  border-bottom-right-radius: 75px;
  filter: drop-shadow(0px 10px 40px rgba(0, 0, 0, 0.4));
  position: relative;
  @keyframes float_3 {
    0% {
      transform: translateY(0%);
    }
    25% {
      transform: translateY(-2%);
    }
    75% {
      transform: translateY(4%);
    }
    100% {
      transform: translateY(0px);
    }
  }
`;

const StonesLeftContainer = styled(StonesDeskLeft)`
  position: absolute;
  left: -300px;
  z-index: 3;
  @media (max-width: 1080px) {
    position: absolute;
    width: 200px;
    height: 200px;
    left: 0;
  }
  @media (max-width: 560px) {
    position: absolute;
    width: 45%;
    height: 50%;
    left: 0;
    top: -10%;
  }
  g {
    &:nth-child(odd) {
      animation: float_1 8s ease-in-out infinite 1s;
    }
    &:nth-child(even) {
      animation: float_2 8s ease-in-out infinite 0.5s;
    }
  }
  @keyframes float_1 {
    0% {
      transform: translateY(0%);
    }
    25% {
      transform: translateY(-2%);
    }
    75% {
      transform: translateY(6%);
    }
    100% {
      transform: translateY(0px);
    }
  }
`;

const StonesRightContainer = styled(StonesDeskRight)`
  position: absolute;
  right: -300px;
  z-index: 3;
  g {
    &:nth-child(odd) {
      animation: float_2 8s ease-in-out infinite 1s;
    }
    &:nth-child(even) {
      animation: float_2 8s ease-in-out infinite 0.5s;
    }
  }
  @media (max-width: 1080px) {
    position: absolute;
    width: 200px;
    height: 200px;
    right: calc(50% - 285px);
  }
  @media (max-width: 560px) {
    position: absolute;
    width: 45%;
    height: 50%;
    right: 0;
    top: -10%;
  }
  @keyframes float_2 {
    0% {
      transform: translateY(0%);
    }
    25% {
      transform: translateY(-6%);
    }
    75% {
      transform: translateY(2%);
    }
    100% {
      transform: translateY(0px);
    }
  }
`;
