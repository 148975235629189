import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import styled, { css } from "styled-components";
import Arrow from "/public/images/icons/arrow.svg";
import Loader from "./Loader";

type Item = {
  value: string | number;
  label: string;
  hiddenValue?: any;
  disabled?: boolean;
};

type Props<T extends Item> = {
  items: T[];
  prefix?: React.ReactNode;
  icon?: React.ReactNode;
  value?: T["value"];
  onChange?: (selected: T) => void;
  variant?: MenuVariant;
  disabled?: boolean;
  isLoading?: boolean;
  width?: string;
};

function WrewardsDropdown<T extends Item>({
  items = [],
  prefix,
  icon = <Arrow />,
  value,
  onChange,
  variant = "base",
  disabled,
  isLoading,
  width,
}: Props<T>) {
  return (
    <Menu>
      {({ isOpen }) => (
        <MenuWrapper variant={variant} style={{ width: width ? width : "auto" }}>
          <MenuButton
            isActive={isOpen}
            as={StyledMenuButton}
            disabled={isLoading || disabled || items.length === 0}
          >
            {prefix && <span className="prefix">{prefix}</span>}
            <span className="value">{items.find((i) => i.value === value)?.label ?? value}</span>
            {isLoading ? (
              <Loader
                innerRingProps={{
                  style: {
                    width: 24,
                    height: 24,
                  },
                }}
                outerRingProps={{
                  style: {
                    width: 24,
                    height: 24,
                  },
                }}
              />
            ) : (
              icon && <span className="icon">{icon}</span>
            )}
          </MenuButton>
          <MenuList rootProps={{ width: "100%" }} as={StyledList}>
            {items.map((item) => (
              <MenuItem
                key={item.value}
                disabled={item.disabled}
                value={item.value}
                as={MenuItemStyled}
                onClick={() => onChange?.(item)}
                aria-selected={item.value === value}
              >
                {item.label}
              </MenuItem>
            ))}
          </MenuList>
        </MenuWrapper>
      )}
    </Menu>
  );
}

type MenuVariant = keyof typeof variants | string;

const MenuWrapper = styled.div<{ variant?: MenuVariant }>`
  position: relative;
  display: flex;
  justify-items: stretch;
  button {
    flex-grow: 1;
    width: 100%;
  }

  ${({ variant = "base" }) =>
    // @ts-ignore
    variants?.[variant as t] ? variants?.[variant as t] : variants.primary}
`;
const MenuItemStyled = styled.button`
  &.chakra-menu__menuitem {
    font-family: var(--font-family-golos);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: rgba(171, 186, 219, 0.5);
    /* color: #00b2ff; */
    padding: 8px 6px;

    &:hover,
    &:focus,
    &[aria-selected="true"] {
      color: #00b2ff;
      background-color: rgba(0, 178, 255, 0.05);
      border-radius: 8px;
    }
  }
`;

const StyledList = styled(MenuList)`
  position: relative;
  &.chakra-menu__menu-list {
    overflow: hidden;
    padding: 6px;
    transition: border 0.2s ease-in-out;
    /* --menu-bg: #181e30; */
    border-radius: 8px;
    border: 1px solid var(--pure-sky-100, #00b2ff);
    /* background: var(--pure-sky-5, rgba(0, 178, 255, 0.05)); */
  }
`;

const StyledMenuButton = styled.button`
  border-radius: 8px;
  background: var(--Early-Morning, #1e263e);
  font-family: var(--font-family-golos);
  font-size: 14px;
  font-weight: 500;
  color: rgba(171, 186, 219, 0.5);
  padding: 12px 16px;
  border: 1px solid var(--dark-foggy-night-50, rgba(29, 34, 52, 0.5));
  transition: all 0.2s ease-in-out;
  .icon {
    width: 24px;
    height: 24px;
    border-radius: 40px;
    border: 2px solid var(--clouds-25, rgba(171, 186, 219, 0.03));
    background: var(--clouds-10, rgba(171, 186, 219, 0.1));
    transition: 0.2s all;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-180deg);
    svg {
      width: 100%;
      height: 100%;
    }
  }

  &[aria-expanded="true"] {
    border-color: #00b2ff;
    background: var(--pure-sky-5, rgba(0, 178, 255, 0.05));

    .icon {
      border: 2px solid var(--pure-sky-5, rgba(0, 178, 255, 0.05));
      background: var(--pure-sky-15, rgba(0, 178, 255, 0.15));
      transform: rotate(0deg);
    }

    .value {
      color: #00b2ff;
    }
  }

  &:disabled {
    cursor: not-allowed;
  }

  & > span {
    display: flex;
    gap: 8px;
    align-items: center;
    text-align: left;

    .icon {
      margin-left: auto;
    }
  }

  .value {
    min-width: 80px;
    /* color: #00b2ff; */
  }
`;
const variants = {
  base: css`
    ${StyledList} {
      --menu-bg: #181e30;
    }
  `,
  game: css`
    ${StyledMenuButton} {
      background: #141722;
      border: 1px solid var(--dark-foggy-night-50, #1d223480);
      color: #abbadb;
    }

    ${StyledList} {
      --menu-bg: #141722;
    }
  `,
  small_base: css`
    ${StyledList} {
      --menu-bg: #141722;
    }
    ${StyledMenuButton} {
      background: #141722;
      padding: 6px 8px;
    }
  `,
  small_primary: css`
    ${StyledList} {
      --menu-bg: #141722;
    }
    ${StyledMenuButton} {
      background: var(--pure-sky-color);
      padding: 6px 8px;
    }
  `,
};

export default WrewardsDropdown;
